<template>
  <div
    id="newEditPhoneModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="newEditPhoneModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <ValidationObserver ref="newEditPhoneForm" v-slot="{ handleSubmit }" slim>
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="newEditUserModalLabel" class="modal-title">
              {{ modalTitle }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="resetForm(true)"
            ></button>
          </div>
          <div class="modal-body">
            <ValidationProvider
              tag="div"
              rules="required"
              name="Full Name"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="fullName" class="col-form-label">Full Name:</label>
              <input
                type="text"
                v-model="newEditPhone_data.name"
                id="fullname"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required"
              name="Phone"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="phone" class="col-form-label">Phone:</label>
              <select
                v-model="newEditPhone_data.phone"
                name="phone"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              >
                <option
                  v-for="(phone, index) in phoneLines"
                  :key="index"
                  :value="phone"
                >
                  {{ phone }}
                </option>
              </select>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              rules="required|numeric"
              name="Extension"
              v-slot="{ errors }"
              class="mb-3"
            >
              <label for="extension" class="col-form-label">Extension:</label>
              <input
                type="number"
                v-model.number="newEditPhone_data.extension"
                id="extension"
                :class="{ 'form-control': true, 'is-invalid': errors[0] }"
              />
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click.prevent="resetForm(true)"
            >
              Close
            </button>
            <button
              v-if="!existingPhone"
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(createPhone)"
            >
              Create Phone
              <loader :isLoading="isLoading"></loader>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-primary"
              @click.prevent="handleSubmit(editPhone)"
            >
              Update Phone
              <loader :isLoading="isLoading"></loader>
            </button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import loader from "./loader";
import { Modal } from "bootstrap";
import { getters, mutations } from "../store";
export default {
  data() {
    return {
      modal: null,
      newEditPhone_data: {
        name: "",
        phone: "",
        extension: "",
      },
      isLoading: false,
    };
  },
  components: {
    loader,
  },
  mounted() {
    this.modal = new Modal(document.getElementById("newEditPhoneModal"));
  },
  computed: {
    existingPhone() {
      return "_id" in this.selectedPhone;
    },
    selectedPhone() {
      return getters.selectedPhone();
    },
    users() {
      return getters.users();
    },
    phoneLines() {
      return getters.phoneLines();
    },
    modalTitle() {
      return `${this.existingPhone ? "Edit" : "New"} Phone`;
    },
  },
  watch: {
    selectedPhone: {
      handler(selectedPhone) {
        if (this.existingPhone) {
          this.newEditPhone_data = { ...selectedPhone };
        } else {
          this.resetForm();
        }
      },
    },
  },
  methods: {
    createPhone() {
      this.isLoading = true;
      this.$axios
        .post("/phones/create", this.newEditPhone_data)
        .then(({ data: { success, message, createdPhone } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            this.resetForm(true);
            mutations.setPhone(createdPhone);
          }
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    editPhone() {
      this.isLoading = true;
      this.$axios
        .patch(`/phones/${this.newEditPhone_data._id}`, this.newEditPhone_data)
        .then(({ data: { success, message, updatedPhone } }) => {
          window.scrollTo(0, 0);
          this.isLoading = false;
          if (success) {
            mutations.updatePhone(updatedPhone);
          }
          this.resetForm(true);
          this.showResponse({ success, message });
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    showResponse(response) {
      this.modal.hide();
      mutations.showToast(response);
    },
    showModal() {
      this.modal.show();
    },
    selectDepartment(event) {
      const index = event.target.selectedIndex;
      const option = event.target.options[index];
      const optgroup = option.parentElement;
      const department = optgroup.getAttribute("label");
      this.newEditPhone_data.department = department;
    },
    resetForm(clearPhone = false) {
      this.$nextTick(() => {
        this.newEditPhone_data = {
          name: "",
          phone: "",
          extension: "",
          branch: "",
          department: "",
        };
        if (clearPhone) {
          mutations.selectPhone();
        }
        this.$refs.newEditPhoneForm.reset();
      });
    },
  },
};
</script>
