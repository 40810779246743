<template>
  <div>
    <div class="tile">
      <div v-if="isAdmin" class="d-flex flex-row-reverse">
        <button
          type="button"
          class="btn btn-primary mb-2"
          @click.prevent="showModal('newEditPhoneModal')"
        >
          <i class="fas fa-plus"></i> New Phone
        </button>
      </div>
      <vue-good-table
        ref="phonesTable"
        :rows="phones"
        :columns="columns"
        :pagination-options="{ enabled: true }"
        :search-options="{ enabled: true, placeholder: 'Search' }"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'buttons'" class="text-center">
            <button
              type="button"
              class="btn btn-primary me-2"
              @click.prevent="selectPhone(props.row, 'newEditPhoneModal')"
            >
              <i class="fas fa-edit"></i>
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click.prevent="selectPhone(props.row, 'deletePhoneModal')"
            >
              <i class="fas fa-minus-circle"></i>
            </button>
          </div>
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate" class="text-center">
          <span>No data</span>
        </div>
      </vue-good-table>
      <deletePhone ref="deletePhoneModal"></deletePhone>
      <newEditPhone ref="newEditPhoneModal"></newEditPhone>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import deletePhone from "../components/deletePhone";
import newEditPhone from "../components/newEditPhone";
export default {
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Phone",
          field: "phone",
        },
        {
          label: "Extension",
          field: "extension",
        },
        {
          label: "",
          field: "buttons",
          hidden: false,
        },
      ],
    };
  },
  created() {
    this.getPhones();
  },
  computed: {
    loggedInUser() {
      return getters.userLoggedIn();
    },
    phones() {
      return getters.phones();
    },
    isAdmin() {
      return getters.isAdmin();
    },
  },
  watch: {
    isAdmin: {
      handler(permission) {
        this.$set(this.columns[3], "hidden", !permission);
      },
      immediate: true,
    },
  },
  components: {
    deletePhone,
    newEditPhone,
  },
  methods: {
    getPhones() {
      this.$axios({
        url: "/phones",
      })
        .then(({ data: { phonesList } }) => {
          mutations.setPhones(phonesList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectPhone(phone, modalID) {
      this.showModal(modalID);
      mutations.selectPhone(phone);
    },
    showModal(modalID) {
      this.$refs[modalID].showModal();
    },
  },
};
</script>
